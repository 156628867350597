import gql from 'graphql-tag';

import { MEDIAREF } from './AssetLibrary';
import { ME_FRAGMENT } from './Users';

export const STATISTIC_FRAGMENT = gql`
  fragment Statistic on Statistic {
    id
    name
    className
    type
    value
    units
    filter
    history {
      date
      value
    }
  }
`;

export const STATS_GRAPH_FRAGMENT = gql`
  fragment StatsGraph on StatsGraph {
    name
    stack
    render
    stats {
      ...Statistic
    }
  }

  ${STATISTIC_FRAGMENT}
`;

const TEAM_MEMBER_FRAGMENT = gql`
  fragment TeamMember on UserInTeam {
    id
    firstName
    lastName
    profileMediaRef {
      ...MediaRef
    }
    email
    role
    invitationStatus
    lastCountry
  }
  ${MEDIAREF}
`;

const ATTENDEE_IN_TEAM_FRAGMENT = gql`
  fragment AttendeeInTeam on AttendeeInTeam {
    id
    visitorId
    firstName
    lastName
    email
    country
    subscribed
    team {
      id
      name
    }
    ewebinar {
      id
      title
    }
    set {
      id
      draftWebinar {
        id
        title
      }
    }
  }
`;

const TEAM_WITH_STATS_FRAGMENT = gql`
  fragment TeamWithStats on Team {
    id
    name
    logoMediaRef {
      ...MediaRef
    }
    favIconMediaRef {
      ...MediaRef
    }
    primaryColor
    actionColor
    subdomain
    shareUrl
    subscriptionStatus
    users {
      ...TeamMember
    }
    stats {
      ...Statistic
    }
    graphs {
      ...StatsGraph
    }
    isArchived
  }
  ${MEDIAREF}
  ${STATS_GRAPH_FRAGMENT}
  ${TEAM_MEMBER_FRAGMENT}
`;

export const OPS_ALLTEAMS = gql`
  query opsSearchTeams($filter: CrossTeamFilterInput!) {
    opsSearchTeams(filter: $filter) {
      teams {
        ...TeamWithStats
      }
      total
      stats {
        ...Statistic
      }
      nextCursor
    }
  }
  ${TEAM_WITH_STATS_FRAGMENT}
`;

export const TEAM_WITH_STATS = gql`
  query teamWithStats($filter: CrossTeamFilterInput!) {
    teamWithStats(filter: $filter) {
      ...TeamWithStats
    }
  }
  ${TEAM_WITH_STATS_FRAGMENT}
`;

export const OPS_SEARCH = gql`
  query searchTeams($filter: TeamsInput!) {
    searchTeams(filter: $filter) {
      data {
        id
        name
        logoMediaRef {
          ...MediaRef
        }
        favIconMediaRef {
          ...MediaRef
        }
        subdomain
        users {
          name
        }
      }
      total
      nextCursor
    }
  }

  query searchUsers($filter: OutActionSearchInput!) {
    searchUsers(filter: $filter) {
      data {
        id
        firstName
        lastName
        profileMediaRef {
          ...MediaRef
        }
        email
        role
        invitationStatus
        lastCountry
        teamId
      }
      total
      nextCursor
    }
  }

  query searchEmailsOut($filter: OutActionSearchInput!) {
    searchEmailsOut(filter: $filter) {
      data {
        messageId
        from
        subject
        to
        attendeeId
        userId
        createdAt
        unsubscribed
        complained
        opened
        bounced
      }
      total
      nextCursor
    }
  }

  ${MEDIAREF}
`;

const ATTENDEE_SEARCH_INFO_FRAGMENT = gql`
  fragment AttendeeSearchInfo on AttendeeSearchInfo {
    id
    firstName
    lastName
    email
  }
`;

export const SEARCH_ATTENDEES = gql`
  query searchAttendees($filter: AttendeesInput!) {
    searchAttendees(filter: $filter) {
      data {
        ...AttendeeSearchInfo
      }
      total
      nextCursor
    }
  }
  ${ATTENDEE_SEARCH_INFO_FRAGMENT}
`;

export const ATTENDEE_WITH_EMAIL_FRAGMENT = gql`
  fragment AttendeeWithStats on AttendeeWithStats {
    id
    visitorId
    firstName
    lastName
    email
    country
    subscribed
    totalAttendees
    totalTeams
    totalEwebinars
  }
`;

export const OPS_GET_ATTENDEE_INFO = gql`
  query opsGetAttendeeInfo($email: String!) {
    opsGetAttendeeInfo(email: $email) {
      ...AttendeeWithStats
    }
  }
  ${ATTENDEE_SEARCH_INFO_FRAGMENT}
`;

export const SEARCH_ATTENDEES_BY_EMAIL = gql`
  query searchAttendeesByEmail($filter: AttendeesEmailsGroupInput!) {
    searchAttendeesByEmail(filter: $filter) {
      data {
        ...AttendeeInTeam
      }
      total
      nextCursor
    }
  }
  ${ATTENDEE_IN_TEAM_FRAGMENT}
`;

export const ATTENDEES_OPTOUT_ACROSS_TEAMS = gql`
  mutation attendeesOptOutAcrossTeams($email: String!, $optOut: Boolean!) {
    attendeesOptOutAcrossTeams(email: $email, optOut: $optOut)
  }
`;

export const DELETE_ATTENDEES_ACROSS_TEAMS = gql`
  mutation deleteAttendeesAcrossTeams($email: String!) {
    deleteAttendeesAcrossTeams(email: $email)
  }
`;

export const DELETE_ATTENDEE = gql`
  mutation deleteAttendee($attendeeId: String!) {
    deleteAttendee(attendeeId: $attendeeId)
  }
`;

export const OPS_STATS = gql`
  query opsStats($filter: CrossTeamFilterInput!, $page: String!) {
    opsStats(filter: $filter, page: $page) {
      ...Statistic
    }
  }
  ${STATISTIC_FRAGMENT}
`;

export const OPS_TRACKED_STATS = gql`
  query opsStatsHistory($filter: StatsHistoryFilter!) {
    opsStatsHistory(filter: $filter) {
      ...Statistic
    }
  }
  ${STATISTIC_FRAGMENT}
`;

export const OPS_GRAPHS = gql`
  query opsGraphs($filter: CrossTeamFilterInput!, $page: String!) {
    opsGraphs(filter: $filter, page: $page) {
      ...StatsGraph
    }
  }
  ${STATS_GRAPH_FRAGMENT}
`;

export const OPS_STAT_ITEMS = gql`
  query opsStatItems($stat: StatisticInput!, $cursor: String!) {
    opsStatItems(stat: $stat, cursor: $cursor) {
      items {
        __typename
        ... on EWebinarSet {
          id
          draftWebinar {
            id
            title
          }
        }
        ... on Attendee {
          id
          firstName
          lastName
          email
          sessionType
          startTime
          joinTime
          joinReplayTime
          optOut
          country
          deletedAt
        }
        ... on Team {
          id
          name
          subdomain
        }

        ... on HistoricStat {
          value
          date
        }
      }
      total
      nextCursor
    }
  }
`;

export const OPERATORS = gql`
  query opsOperations {
    opsOperations {
      operator
      group
      name
      desc
      args
    }
  }
`;

export const REFRESH_HUBSPOT = gql`
  mutation performOperation($op: String!, $args: JSON) {
    performOperation(op: $op, args: $args)
  }
`;

export const IMPERSONATE = gql`
  mutation impersonate($teamId: String!, $userId: String!) {
    impersonate(teamId: $teamId, userId: $userId) {
      ...Me
    }
  }
  ${ME_FRAGMENT}
`;

export const IMPERSONATE_BY_EMAIL = gql`
  mutation impersonateByEmail($email: String!, $userId: String) {
    impersonateByEmail(email: $email, userId: $userId) {
      ...Me
    }
  }
  ${ME_FRAGMENT}
`;

export const STOP_IMPERSONATING = gql`
  mutation stopImpersonating {
    stopImpersonating {
      ...Me
    }
  }
  ${ME_FRAGMENT}
`;
