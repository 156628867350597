import gql from 'graphql-tag';

import { MEDIAREF } from './AssetLibrary';
import { INTERACTION_FRAGMENT } from './Interactions';
import { NOTIFICATION_FRAGMENT } from './Notifications';
import { STATISTIC_FRAGMENT } from './Ops';
import {
  PAGE_SECTION_FRAGMENT,
  PUBLIC_EWEBINAR_FRAGMENT,
  REGISTRATION_FORM_SETTING_FRAGMENT,
  SCHEDULE_SETTINGS_FRAGMENT,
  SOCIAL_MEDIA_TAGS_FRAGMENT,
  UPLOAD_STATUS_FRAGMENT,
  EWEBINAR_FRAGMENT,
  PUBLIC_SET_SETTINGS_FRAGMENT,
} from './PublicEWebinarFragment';
import { SCRIPT_IN_SET_FRAGMENT } from './Script';
import { TEAM_SETTINGS_FRAGMENT } from './TeamFragment';
import { USER_SET_FRAGMENT } from './Users';

export const MAX_PRESENTERS = 2;

export const SET_SETTINGS_FRAGMENT = gql`
  fragment SetSettings on SetSettings {
    pixelID
    replaySettings {
      useRetargetingLink
      disallowSeek
    }
    enableUngatedOnDemand
    registrationNotificationSettings {
      enableNotified
      type
    }
    accessControl {
      enableAccessControl
      type
      limitPerSession
      allowedSettings {
        emails
        domains
      }
      blockedSettings {
        emails
        domains
      }
      requireSSO
      restrictions {
        noDisposableEmails
        noFreeEmails
        requireCaptcha
      }
    }
    chatNotRespondedEmailTo
  }
`;

export const SET_IDS_FRAGMENT = gql`
  fragment EWebinarSetIDs on EWebinarSet {
    id
    assignedModerators {
      id
      email
      displayEmail
    }
    publicWebinar {
      id
      title
    }
    draftWebinar {
      id
      title
    }
    isPublishable
    attendeeChatEmailReplyTo
    setSettings {
      ...SetSettings
    }
    expireReplayLinkAfterMins
    notifiedUser {
      id
      email
      displayEmail
    }
  }
  ${SET_SETTINGS_FRAGMENT}
`;

export const UPDATE_LIVE_FRAGMENT = gql`
  fragment UpdateLiveFragment on EWebinarSet {
    liveFragment {
      id
      unreadChats
      inSession
    }
  }
`;

export const UPDATE_UPLOAD_STATUS_FRAGMENT = gql`
  fragment UpdateUploadStatus on EWebinar {
    uploadStatus {
      id
      uuid
      stage
      type
      progress
      done
      error
      urls {
        name
        sourceUrl
        uploadUrl
        playUrl
        uri
        vimeoId
        thumbnailMediaRef {
          ...MediaRef
        }
      }
    }
  }
  ${MEDIAREF}
`;

export const TEMPLATE_INFO_FRAGMENT = gql`
  fragment templateInfo on TemplateInfo {
    name
    author
    hubspotUrl
    language
    recommended
    askForVideoUpload
    order
    templateMediaRef {
      ...MediaRef
    }
  }
  ${MEDIAREF}
`;

export const EWEBINAR_INFO = gql`
  fragment EWebinarInfo on EWebinar {
    id
    title
    startDate
    duration
    thumbnailMediaRef {
      ...MediaRef
    }
    logoMediaRef {
      ...MediaRef
    }
    favIconMediaRef {
      ...MediaRef
    }
    primaryColor
    actionColor
    borderRadius
    vimeoVideoId
    vimeoTextTrackId
    videoPlayUrl
    videoSourceUrl
    timeZone
    viewerRoomSettings {
      language
    }
    justInTimeModeEnabled
    scheduleType
    scheduleSettings {
      justInTimeIntervalMinutes
      showReplaySession
    }
    registrationPageSettings {
      customRegistrationUrl
      sections {
        ...PageSection
      }
      widgetConfig {
        ctaBtnText
        ctaBtnBgColor
      }
      specifySocialTags
      socialMediaTags {
        ...socialMediaTags
      }
    }
    registrationFormSettings {
      ...RegistrationFormSettings
    }
    uploadStatus {
      ...UploadStatus
    }
    presenters {
      id
      name
      profileMediaRef {
        ...MediaRef
      }
    }
    set {
      id
    }
    isExternalHost
  }
  ${SOCIAL_MEDIA_TAGS_FRAGMENT}
  ${UPLOAD_STATUS_FRAGMENT}
  ${PAGE_SECTION_FRAGMENT}
  ${REGISTRATION_FORM_SETTING_FRAGMENT}
`;

export const SET_INFO = gql`
  fragment SetInfo on EWebinarSet {
    id
    tags
    updatedAt
    analyticsStartDate
    isPublishable
    attendeeChatEmailReplyTo
    templateID
    templateTypes
    templateInfo {
      ...templateInfo
    }
    urls {
      setUrl
      shortUrl
      registration
      replayRegistration
      retargetingReplay
      ungatedOnDemand
    }
    assignedModerators {
      id
      firstName
      lastName
      name
      email
    }
    publicWebinar {
      ...EWebinarInfo
    }
    draftWebinar {
      ...EWebinarInfo
      createdAt
      updatedAt
    }
    liveFragment {
      id
      unreadChats
      inSession
    }
    setSettings {
      ...SetSettings
    }
    expireReplayLinkAfterMins
  }
  ${TEMPLATE_INFO_FRAGMENT}
  ${EWEBINAR_INFO}
  ${SET_SETTINGS_FRAGMENT}
  ${STATISTIC_FRAGMENT}
`;

// Minimal webinar info on Home page
export const EWEBINAR_PREVIEW_INFO = gql`
  fragment EWebinarPreviewInfo on EWebinar {
    id
    title
    duration
    thumbnailMediaRef {
      ...MediaRef
    }
    isDisabledChat
  }
  ${MEDIAREF}
`;

export const SET_ANALYTICS_SUMMARY = gql`
  fragment EWebinarSetAnalyticsSummary on EWebinarSetAnalyticsSummary {
    registered
    attended
    engaged
  }
`;

export const HOME_PAGE_SET = gql`
  fragment HomePageSet on HomePageSet {
    id
    group
    title
    editedAt
    set {
      id
      isPublishable
      tags
      draftWebinar {
        id
      }
      publicWebinar {
        id
      }
      liveFragment {
        id
        unreadChats
        inSession
      }
      analytics {
        ...EWebinarSetAnalyticsSummary
      }
    }
  }
  ${SET_ANALYTICS_SUMMARY}
`;

// minimal Set info on Home page
export const SET_PREVIEW_INFO = gql`
  fragment SetPreviewInfo on EWebinarSet {
    id
    fromTemplateID
    tags
    updatedAt
    isPublishable
    templateID
    templateTypes
    templateInfo {
      ...templateInfo
    }
    assignedModerators {
      name
    }
    publicWebinar {
      ...EWebinarPreviewInfo
    }
    draftWebinar {
      ...EWebinarPreviewInfo
      uploadStatus {
        ...UploadStatus
      }
      createdAt
      updatedAt
      editedAt
    }
    liveFragment {
      id
      unreadChats
      inSession
    }
    setSettings {
      ...SetSettings
    }
    expireReplayLinkAfterMins
    analytics {
      ...EWebinarSetAnalyticsSummary
    }
    socialMediaTags {
      ...socialMediaTags
    }
  }
  ${SET_ANALYTICS_SUMMARY}
  ${SOCIAL_MEDIA_TAGS_FRAGMENT}
  ${TEMPLATE_INFO_FRAGMENT}
  ${SET_SETTINGS_FRAGMENT}
  ${EWEBINAR_PREVIEW_INFO}
  ${UPLOAD_STATUS_FRAGMENT}
`;

export const SET_FRAGMENT = gql`
  fragment EWebinarSet on EWebinarSet {
    ...SetInfo
    id
    updatedAt
    analyticsStartDate
    isPublishable
    attendeeChatEmailReplyTo
    tags
    createdAt
    urls {
      slugId
      shortUrl
      registration
      replayRegistration
      allWebinars
      retargetingReplay
      ungatedOnDemand
    }
    notifiedUser {
      id
    }
    publicWebinar {
      id
    }
    setSettings {
      ...SetSettings
    }
    expireReplayLinkAfterMins
    templateID
    templateTypes
    templateInfo {
      ...templateInfo
    }
    hideFromCatalog
    liveFragment {
      id
      unreadChats
      inSession
    }
    useScripts {
      ...ScriptInSet
    }
    assignedModerators {
      ...UserInSet
    }
    primaryModerator {
      id
      name
      firstName
      lastName
      email
      profileMediaRef {
        ...MediaRef
      }
    }
    team {
      id
      name
      shareUrl
      settings {
        ...TeamSettings
      }
    }
    socialMediaTags {
      ...socialMediaTags
    }
  }
  ${MEDIAREF}
  ${SOCIAL_MEDIA_TAGS_FRAGMENT}
  ${SET_INFO}
  ${TEMPLATE_INFO_FRAGMENT}
  ${SET_SETTINGS_FRAGMENT}
  ${SCRIPT_IN_SET_FRAGMENT}
  ${USER_SET_FRAGMENT}
  ${TEAM_SETTINGS_FRAGMENT}
`;

export const TEMPLATE_FRAGMENT = gql`
  fragment Template on EWebinarSet {
    id
    updatedAt
    templateID
    templateTypes
    templateInfo {
      ...templateInfo
    }
  }
  ${TEMPLATE_INFO_FRAGMENT}
`;

export const GET_TEMPLATE_TYPES = gql`
  query templateTypes {
    templateTypes
  }
`;

export const GET_TEAM_TEMPLATE_TYPES = gql`
  query teamTemplateTypes {
    teamTemplateTypes
  }
`;

export const GET_TEMPLATES = gql`
  query templates($filter: TemplatesFilterInput) {
    templates(filter: $filter) {
      templates {
        ...Template
      }
      nextCursor
    }
  }
  ${TEMPLATE_FRAGMENT}
`;

export const USE_TEMPLATE = gql`
  mutation useEWebinarTemplate($templateId: String!, $data: CreateEwebinarInput) {
    useEWebinarTemplate(templateId: $templateId, data: $data) {
      id
      draftWebinar {
        ...EWebinar
      }
    }
  }
  ${SET_FRAGMENT}
  ${EWEBINAR_FRAGMENT}
`;

export const PUBLISH_AS_TEMPLATE = gql`
  mutation publishAsTemplate($setId: String!, $templateInput: TemplateInput) {
    publishAsTemplate(setId: $setId, templateInput: $templateInput) {
      ...Template
    }
  }
  ${TEMPLATE_FRAGMENT}
`;

export const UPDATE_TEMPLATE_INFO = gql`
  mutation updateTemplateInfo($setId: String!, $templateInput: TemplateInput!) {
    updateTemplateInfo(setId: $setId, templateInput: $templateInput) {
      ...Template
    }
  }
  ${TEMPLATE_FRAGMENT}
`;

export const EWEBINAR_WITH_SET_FRAGMENT = gql`
  fragment EWebinarWithSet on EWebinar {
    ...EWebinar
    set {
      ...EWebinarSet
      templateID
    }
  }
  ${EWEBINAR_FRAGMENT}
  ${SET_FRAGMENT}
`;

export const EWEBINAR_WITH_SETINFO_FRAGMENT = gql`
  fragment EWebinarWithSetInfo on EWebinar {
    ...EWebinar
    set {
      ...SetInfo
    }
  }
  ${EWEBINAR_FRAGMENT}
  ${SET_INFO}
`;

export const CREATE_EWEBINAR = gql`
  mutation createEwebinar($data: CreateEwebinarInput!) {
    createEwebinar(data: $data) {
      id
      title
      vimeoVideoId
      thumbnailMediaRef {
        ...MediaRef
      }
      uploadStatus {
        ...UploadStatus
      }
    }
  }
  ${UPLOAD_STATUS_FRAGMENT}
`;

export const GET_EWEBINAR_WITH_SET = gql`
  query ewebinarWithSet($id: String!) {
    ewebinar(id: $id) {
      ...EWebinarWithSet
    }
  }
  ${EWEBINAR_WITH_SET_FRAGMENT}
`;

export const GET_PUBLIC_EWEBINAR = gql`
  query publicEwebinar($id: String!) {
    ewebinar(id: $id) {
      ...PublicEWebinar
    }
  }
  ${PUBLIC_EWEBINAR_FRAGMENT}
`;

export const UPDATE_EWEBINAR = gql`
  mutation updateEwebinar($data: UpdateEwebinarInput!) {
    updateEwebinar(data: $data) {
      ...EWebinarWithSet
    }
  }
  ${EWEBINAR_WITH_SET_FRAGMENT}
`;

export const UPDATE_UPLOAD_STATUS = gql`
  mutation updateUploadStatus($ewebinarId: String!, $data: UploadStatusInput!) {
    updateUploadStatus(ewebinarId: $ewebinarId, data: $data) {
      ...EWebinar
    }
  }
  ${EWEBINAR_FRAGMENT}
`;

export const DELETE_EWEBINAR = gql`
  mutation deleteEwebinar($id: String!) {
    deleteEwebinar(id: $id)
  }
`;

export const PREPAIR_PUBLISH_EWEBINAR = gql`
  query prepairPublishEwebinar($setId: String!) {
    prepairPublishEwebinar(setId: $setId) {
      date
      additional
      no
      billingCycle
      upgradeRequired
      nextPlan {
        id
        name
        from
        to
        monthlyPrice
        pricePerAdditional
      }
      isEmptySession
    }
  }
`;

export const PUBLISH_EWEBINAR = gql`
  mutation publishEwebinar($setId: String!, $paymentIntentId: String) {
    publishEwebinarWith3DS(setId: $setId, paymentIntentId: $paymentIntentId) {
      paymentIntentSecret
      set {
        ...EWebinarSet
        team {
          id
          pendingSetupIntent
        }
      }
    }
  }
  ${SET_FRAGMENT}
`;

export const UNPUBLISH_EWEBINAR = gql`
  mutation unpublishEwebinar($id: String!) {
    unpublishEwebinar(id: $id) {
      id
      draftWebinar {
        id
        isPublished
      }
      publicWebinar {
        id
      }
      team {
        id
        pendingSetupIntent
      }
    }
  }
`;

export const DUPLICATE_EWEBINAR = gql`
  mutation duplicate($setId: String!) {
    duplicateEwebinar(setId: $setId) {
      id
      isPublished
      set {
        id
      }
    }
  }
`;

export const CLONE_EWEBINAR = gql`
  mutation cloneAcrossTeams($setId: String!, $teamId: String) {
    cloneAcrossTeams(setId: $setId, teamId: $teamId) {
      id
    }
  }
`;

export const DISCARD_EWEBINAR_CHANGES = gql`
  mutation discardEwebinarChanges($setId: String!) {
    discardEwebinarChanges(setId: $setId) {
      id
    }
  }
`;

export const EWEBINAR_PUBLIC_DESCRIPTION = gql`
  fragment PublicWebinarDescription on EWebinar {
    id
    title
    isPublished
    primaryColor
    actionColor
    font
    fontSize
    fontCssUrl
    logoMediaRef {
      ...MediaRef
    }
    thumbnailMediaRef {
      ...MediaRef
    }
    vimeoVideoId
    vimeoTextTrackId
    startDate
    endDateEnabled
    endDate
    justInTimeModeEnabled
    duration
    timeZone
    registrationFormSettings {
      registerButton
    }
    registrationPageSettings {
      customRegistrationUrl
      specifySocialTags
      sections {
        ...PageSection
      }
      socialMediaTags {
        ...socialMediaTags
      }
    }
    scheduleType
    scheduleSettings {
      ...scheduleSettings
    }
    viewerRoomSettings {
      language
    }
    presenters {
      id
      name
      profileMediaRef {
        ...MediaRef
      }
    }
    set {
      id
      urls {
        registration
      }
    }
    fullSessions {
      session
      status
    }
  }
  ${MEDIAREF}
  ${SOCIAL_MEDIA_TAGS_FRAGMENT}
  ${PAGE_SECTION_FRAGMENT}
  ${SCHEDULE_SETTINGS_FRAGMENT}
`;

export const PUBLIC_SET_FRAGMENT = gql`
  fragment PublicSet on EWebinarSet {
    id
    isPublishable
    now
    primaryModerator {
      id
      name
      firstName
      lastName
      profileMediaRef {
        ...MediaRef
      }
    }
    assignedModerators {
      id
      firstName
      lastName
      name
      profileMediaRef {
        ...MediaRef
      }
    }
    urls {
      slugId
      setUrl
      shortUrl
      registration
      allWebinars
      replayRegistration
      retargetingReplay
      ungatedOnDemand
    }
    setSettings {
      ...PublicSetSettings
    }
    expireReplayLinkAfterMins
    socialMediaTags {
      ...socialMediaTags
    }
    useScripts {
      registrationPage
      registrationForm
      thankyouPage
      presentationRoom
      script {
        id
        uuid
        name
        content
        disableIfGdprDeclined
      }
    }
    team {
      id
      logoMediaRef {
        ...MediaRef
      }
      name
      shareUrl
      settings {
        ...TeamSettings
      }
    }
  }
  ${MEDIAREF}
  ${TEAM_SETTINGS_FRAGMENT}
  ${SOCIAL_MEDIA_TAGS_FRAGMENT}
  ${PUBLIC_SET_SETTINGS_FRAGMENT}
`;

export const PUBLIC_SET_WITH_EWEBINAR_FRAGMENT = gql`
  fragment PublicSetWithEWebinar on EWebinarSet {
    ...PublicSet
    publicWebinar {
      ...PublicEWebinar
    }
  }
  ${PUBLIC_SET_FRAGMENT}
  ${PUBLIC_EWEBINAR_FRAGMENT}
`;

export const PUBLIC_SET = gql`
  query publicSet($id: String!, $forPublish: Boolean) {
    publicSet(id: $id, forPublish: $forPublish) {
      ...PublicSetWithEWebinar
    }
  }
  ${PUBLIC_SET_WITH_EWEBINAR_FRAGMENT}
`;

export const SET_WITH_DRAFT = gql`
  query draftSet($id: String, $templateID: String) {
    set(id: $id, templateID: $templateID) {
      ...EWebinarSet
      draftWebinar {
        ...EWebinar
        interactions {
          ...Interaction
        }
        notifications {
          ...Notification
        }
      }
    }
  }
  ${INTERACTION_FRAGMENT}
  ${NOTIFICATION_FRAGMENT}
  ${EWEBINAR_FRAGMENT}
  ${SET_FRAGMENT}
`;

export const SET_WITH_PUBLIC_EWEBINAR = gql`
  fragment SetWithPublicEwebinar on EWebinarSet {
    ...EWebinarSet
    publicWebinar {
      ...EWebinar
    }
    team {
      id
    }
  }
  ${SET_FRAGMENT}
  ${EWEBINAR_FRAGMENT}
`;

export const SET = gql`
  query set($id: String, $templateID: String) {
    set(id: $id, templateID: $templateID) {
      ...SetWithPublicEwebinar
    }
  }
  ${SET_WITH_PUBLIC_EWEBINAR}
`;

export const UPDATE_UNREAD_CONVERSATION_COUNT_SETS = gql`
  query updateUnreadConversationsCountSets {
    sets {
      id
      liveFragment {
        id
        unreadChats
        inSession
      }
    }
  }
`;

// Get sets on home page
export const GET_SETS_STATUS = gql`
  query homePageSets {
    homePageSets {
      ...HomePageSet
    }
  }
  ${HOME_PAGE_SET}
`;

// Get sets on home page
export const GET_SET_INFO = gql`
  query setPreviewInfo($id: String!) {
    set(id: $id) {
      ...SetPreviewInfo
    }
  }
  ${SET_PREVIEW_INFO}
`;

export const PUBLIC_SET_AND_SESSION = gql`
  fragment PublicSetAndSession on EWebinarSet {
    id
    now
    assignedModerators {
      id
      firstName
      lastName
      name
    }
    urls {
      slugId
      registration
      replayRegistration
      retargetingReplay
      ungatedOnDemand
    }
    publicWebinar {
      ...PublicWebinarDescription
    }
    setSettings {
      ...SetSettings
    }
    expireReplayLinkAfterMins
    socialMediaTags {
      ...socialMediaTags
    }
  }
  ${SOCIAL_MEDIA_TAGS_FRAGMENT}
`;

export const GET_VISIBLE_SETS = gql`
  query visibleSets($teamId: String!, $nextCursor: String!) {
    visibleSets(teamId: $teamId, nextCursor: $nextCursor) {
      sets {
        ...PublicSetAndSession
      }
      nextCursor
    }
  }
  ${PUBLIC_SET_AND_SESSION}
  ${EWEBINAR_PUBLIC_DESCRIPTION}
  ${SET_SETTINGS_FRAGMENT}
`;

export const GET_PUBLIC_SETS = gql`
  query publicSets($teamId: String!, $nextCursor: String!) {
    publicSets(teamId: $teamId, nextCursor: $nextCursor) {
      sets {
        ...PublicSetAndSession
      }
      nextCursor
    }
  }
  ${PUBLIC_SET_AND_SESSION}
  ${EWEBINAR_PUBLIC_DESCRIPTION}
  ${SET_SETTINGS_FRAGMENT}
`;

export const UPDATE_PUBLIC_EWEBINAR = gql`
  mutation updatePublicEWebinar($data: UpdatePublicEWebinarInput!) {
    updatePublicEWebinar(data: $data) {
      ...PublicEWebinar
    }
    ${PUBLIC_EWEBINAR_FRAGMENT}
  }
`;

export const PAGE_SETTINGS_FRAGMENT = gql`
  fragment PageSettings on EWebinar {
    registrationPageSettings {
      sections {
        ...PageSection
      }
    }
    thankyouPageSettings {
      sections {
        ...PageSection
      }
    }
  }
  ${PAGE_SECTION_FRAGMENT}
`;

export const GET_EWEBINAR_SECTIONS_FROM_TEMPLATE = gql`
  query getEwebinarSectionsFromTemplate($setId: String!) {
    ewebinarTemplateFrom(setId: $setId) {
      ...PageSettings
    }
  }
  ${PAGE_SETTINGS_FRAGMENT}
`;

export const BLOCK_REGISTRANTS = gql`
  mutation blockRegistrants($setId: String!, $emails: [String!]!) {
    blockRegistrants(setId: $setId, emails: $emails) {
      id
    }
  }
`;

export const UNBLOCK_REGISTRANTS = gql`
  mutation unblockRegistrants($setId: String!, $emails: [String!]!) {
    unblockRegistrants(setId: $setId, emails: $emails) {
      id
    }
  }
`;

export const UPDATE_EWEBINAR_TAGS = gql`
  mutation updateEwebinarTags($setId: String!, $tags: [String!]!) {
    updateEwebinarTags(setId: $setId, tags: $tags) {
      id
    }
  }
`;

export const GET_WIDGET_SETTINGS = gql`
  query widgetSettings($setId: String!) {
    publicSet(id: $setId) {
      id
      widgetSettings
    }
  }
`;

export const UPDATE_WIDGET_SETTINGS = gql`
  mutation updateWidgetSettings($setId: String!, $widgetSettings: JSON!) {
    updateWidgetSettings(setId: $setId, widgetSettings: $widgetSettings) {
      id
      widgetSettings
    }
  }
`;

export const UPDATE_ANALYTICS_STARTDATE = gql`
  mutation updateAnalyticsSettings($setId: String!, $settings: AnalyticsSettingsInput!) {
    updateAnalyticsSettings(setId: $setId, settings: $settings) {
      set {
        id
        analyticsStartDate
      }
      team {
        id
        timezone
      }
    }
  }
`;

export const CREATE_VIMEO_UPLOAD_URL = gql`
  mutation createVimeoUploadUrl($data: VideoAssetInput!) {
    createVimeoUploadUrl(data: $data) {
      ...UploadStatus
    }
  }
  ${UPLOAD_STATUS_FRAGMENT}
`;

export const WEBINAR_VIDEO_RESPONSE = gql`
  query webinarVideoResponse($ewebinarId: String!) {
    webinarVideoResponse(ewebinarId: $ewebinarId) {
      statusCode
      statusText
    }
  }
`;
