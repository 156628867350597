import gql from 'graphql-tag';

import { MEDIAREF } from './AssetLibrary';
import { TEAM_FRAGMENT, TEAM_SETTINGS_FRAGMENT } from './TeamFragment';

export const PRESENTER_FRAGMENT = gql`
  fragment Presenter on User {
    id
    profileMediaRef {
      ...MediaRef
    }
    name
    firstName
    lastName
    email
    phone
    company
    title
    bio
    displayEmail
    timezone
    socialLinks {
      facebook
      twitter
      linkedin
      instagram
    }
  }
  ${MEDIAREF}
`;

export const TEAM_MEMBER_FRAGMENT = gql`
  fragment Member on UserInTeam {
    id
    profileMediaRef {
      ...MediaRef
    }
    name
    firstName
    lastName
    email
    phone
    company
    title
    bio
    displayEmail
    socialLinks {
      facebook
      twitter
      linkedin
      instagram
    }
    timezone
    state
    version
    isVerified
    team {
      id
      name
      subdomain
      logoMediaRef {
        ...MediaRef
      }
      primaryColor
      actionColor
    }
    assignedSets {
      id
    }
    integrations {
      firstPromoterToken
    }
    role
    invitationStatus
    details {
      occupation
      onboardCompletedAt
    }
  }
  ${MEDIAREF}
`;

export const USER_SET_FRAGMENT = gql`
  fragment UserInSet on UserInSet {
    id
    firstName
    lastName
    name
    email
    profileMediaRef {
      ...MediaRef
    }
    role
    invitationStatus
    setId
    order
  }
  ${MEDIAREF}
`;

export const USER_FRAGMENT = gql`
  fragment User on User {
    ...Presenter
    state
    version
    isVerified
    role
    invitationStatus
    lastCountry
    team {
      id
      name
      subdomain
      logoMediaRef {
        ...MediaRef
      }
      primaryColor
      actionColor
      address {
        country
      }
      settings {
        ...TeamSettings
      }
    }
  }
  ${MEDIAREF}
  ${PRESENTER_FRAGMENT}
  ${TEAM_SETTINGS_FRAGMENT}
`;

export const ME_FRAGMENT = gql`
  fragment Me on Me {
    id
    profileMediaRef {
      ...MediaRef
    }
    lastCountry
    name
    firstName
    lastName
    email
    hasPassword
    phone
    company
    title
    bio
    displayEmail
    integrations {
      firstPromoterToken
    }
    socialLinks {
      facebook
      twitter
      linkedin
      instagram
    }
    timezone
    state
    version
    isVerified
    verifiedAt
    team {
      ...Team
      favIconMediaRef {
        ...MediaRef
      }
      extraScopes
      mailAddress
      promoCode
      billingCycle
      settings {
        ...TeamSettings
      }
      timeSavedSummary
      isEnabledTest
      pendingSetupIntent
    }
    isInvitedUser
    inTeam {
      role
      invitationStatus
      invitedByUser {
        id
        firstName
        lastName
      }
    }
    impersonatingUser {
      userId
      teamId
    }
    chatTokens {
      accessToken
      refreshToken
    }
    details {
      occupation
      onboardCompletedAt
    }
    lastLoginAt
  }
  ${MEDIAREF}
  ${TEAM_FRAGMENT}
  ${TEAM_SETTINGS_FRAGMENT}
`;

export const ME = gql`
  query me {
    me {
      ...Me
      now
    }
  }
  ${ME_FRAGMENT}
`;

export const HUBSPOT_TOKEN = gql`
  query hubspotToken {
    hubspotToken
  }
`;

export const FP_REWARDS = gql`
  mutation registerForRewards {
    registerForRewards {
      ...Me
    }
  }
  ${ME_FRAGMENT}
`;

export const UPDATE_ME = gql`
  mutation updateMe($data: UpdateMeInput!) {
    updateMe(data: $data) {
      ...Me
    }
  }
  ${ME_FRAGMENT}
`;

export const LOGIN = gql`
  mutation login($email: String!, $password: String!, $loginInfo: LoginInfo!) {
    login(email: $email, password: $password, loginInfo: $loginInfo) {
      ...Me
      now
    }
  }

  mutation loginSSO($provider: String!, $token: String!, $loginInfo: LoginInfo!) {
    loginSSO(provider: $provider, token: $token, loginInfo: $loginInfo) {
      ...Me
      now
    }
  }

  ${ME_FRAGMENT}
`;

export const LOGOUT = gql`
  mutation logout {
    logout
  }
`;

export const CHANGE_EMAIL = gql`
  mutation changeEmail($code: String!, $email: String!) {
    changeEmail(code: $code, email: $email) {
      ...Me
    }
  }
  ${ME_FRAGMENT}
`;

export const CHANGE_PASSWORD = gql`
  mutation changePassword($data: ChangePasswordInput!) {
    changePassword(data: $data) {
      ...Me
    }
  }
  ${ME_FRAGMENT}
`;

export const VERIFY_TOKEN = gql`
  mutation verifyToken($token: String!, $loginInfo: LoginInfo!) {
    verifyInviteToken(token: $token, loginInfo: $loginInfo) {
      ...Me
    }
  }
  ${ME_FRAGMENT}
`;

export const VERIFY_NEW_EMAIL = gql`
  mutation startEmailVerify($email: String!, $changingEmail: Boolean!) {
    startEmailVerify(email: $email, changingEmail: $changingEmail)
  }
`;

export const VERIFY_EMAIL_CODE = gql`
  mutation verifyEmailCode($code: String!, $user: NewUserInput!) {
    verifyEmailCode(code: $code, user: $user) {
      firstName
      lastName
      email
      isVerified
    }
  }
`;

export const REGISTER_USER = gql`
  mutation signupUser($code: String!, $user: NewUserAndTeamInput!, $loginInfo: LoginInfo!) {
    signupUser(code: $code, user: $user, loginInfo: $loginInfo) {
      ...Me
    }
  }

  mutation signupUserSSO(
    $provider: String!
    $token: String!
    $team: TeamInput!
    $loginInfo: LoginInfo!
  ) {
    signupUserSSO(provider: $provider, token: $token, team: $team, loginInfo: $loginInfo) {
      ...Me
      now
    }
  }
  ${ME_FRAGMENT}
`;

export const SIGNUP_CC = gql`
  mutation signupTeamCC($data: NewUserAndTeamInput!) {
    signupTeamCC(data: $data) {
      ...Me
    }
  }
  ${ME_FRAGMENT}
`;

export const ACCEPT_INVITATION = gql`
  mutation acceptInvitation($token: String!) {
    acceptInvitation(token: $token) {
      ...Me
    }
  }
  ${ME_FRAGMENT}
`;

export const REJECT_INVITATION = gql`
  mutation rejectInvitation($token: String!) {
    rejectInvitation(token: $token)
  }
`;

export const REFRESH_TOKEN = gql`
  mutation refreshToken {
    refreshToken
  }
`;
