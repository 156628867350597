import React from 'react';
import { Helmet } from 'react-helmet';

declare global {
  interface Window {
    supportedBrowsersWithMinimumVersion?: { name: string; version: number }[];
  }
}

export const BlockUnsupportedBrowsers: React.FC = () => {
  // https://github1s.com/lancedikson/bowser/blob/f09411489ced05811c91cc6670a8e4ca9cbe39a7/src/parser-browsers.js#L30-L698
  return (
    <Helmet>
      <script>{
        /*javascript*/ `
        (function () {
          if (typeof window === 'undefined') return;

          window.supportedBrowsersWithMinimumVersion = [
            {
              name: 'Chrome',
              version: 57,
            },
            {
              name: 'Microsoft Edge',
              version: 18,
            },
            {
              name: 'Firefox',
              version: 51,
            },
            {
              name: 'Opera',
              version: 44,
            },
            {
              name: 'Safari',
              version: 11,
            },
          ];

          var commonVersionIdentifier = new RegExp('version\\\\/(\\\\d+(\\\\.?_?\\\\d+)+)', 'i');

          var getFirstMatch = function getFirstMatch(regexp, ua) {
            var match = ua.match(regexp);
            return match && match.length > 0 && match[1] || '';
          };

          var getSecondMatch = function getSecondMatch(regexp, ua) {
            var match = ua.match(regexp);
            return match && match.length > 1 && match[2] || '';
          };

          var find = function find(arr, predicate) {
            var i;
            var l;

            if (Array.prototype.find) {
              return Array.prototype.find.call(arr, predicate);
            }

            for (i = 0, l = arr.length; i < l; i += 1) {
              var value = arr[i];

              if (predicate(value, i)) {
                return value;
              }
            }

            return undefined;
          };

          var browsersList = [{
            test: [new RegExp('opera', 'i')],
            describe: function describe(ua) {
              var browser = {
                name: 'Opera'
              };
              var version = getFirstMatch(commonVersionIdentifier, ua) || getFirstMatch(new RegExp('(?:opera)[\\\\s/](\\\\d+(\\\\.?_?\\\\d+)+)', 'i'), ua);

              if (version) {
                browser.version = parseFloat(version);
              }

              return browser;
            }
          },

          /* Opera > 13.0 */
          {
            test: [new RegExp('opr\\\\/|opios', 'i')],
            describe: function describe(ua) {
              var browser = {
                name: 'Opera'
              };
              var version = getFirstMatch(new RegExp('(?:opr|opios)[\\\\s/](\\\\S+)', 'i'), ua) || getFirstMatch(commonVersionIdentifier, ua);

              if (version) {
                browser.version = parseFloat(version);
              }

              return browser;
            }
          }, {
            test: [new RegExp('\\\\sedg\\\\/', 'i')],
            describe: function describe(ua) {
              var browser = {
                name: 'Microsoft Edge'
              };
              var version = getFirstMatch(new RegExp('\\\\sedg\\\\/(\\\\d+(\\\\.?_?\\\\d+)+)', 'i'), ua);

              if (version) {
                browser.version = parseFloat(version);
              }

              return browser;
            }
          }, {
            test: [new RegExp('edg([ea]|ios)', 'i')],
            describe: function describe(ua) {
              var browser = {
                name: 'Microsoft Edge'
              };
              var version = getSecondMatch(new RegExp('edg([ea]|ios)\\\\/(\\\\d+(\\\\.?_?\\\\d+)+)', 'i'), ua);

              if (version) {
                browser.version = parseFloat(version);
              }

              return browser;
            }
          }, {
            test: [new RegExp('firefox|iceweasel|fxios', 'i')],
            describe: function describe(ua) {
              var browser = {
                name: 'Firefox'
              };
              var version = getFirstMatch(new RegExp('(?:firefox|iceweasel|fxios)[\\\\s/](\\\\d+(\\\\.?_?\\\\d+)+)', 'i'), ua);

              if (version) {
                browser.version = parseFloat(version);
              }

              return browser;
            }
          }, {
            test: [new RegExp('chrome|crios|crmo', 'i')],
            describe: function describe(ua) {
              var browser = {
                name: 'Chrome'
              };
              var version = getFirstMatch(new RegExp('(?:chrome|crios|crmo)\\\\/(\\\\d+(\\\\.?_?\\\\d+)+)', 'i'), ua);

              if (version) {
                browser.version = parseFloat(version);
              }

              return browser;
            }
          },
          /* Safari */
          {
            test: [new RegExp('safari|applewebkit', 'i')],
            describe: function describe(ua) {
              var browser = {
                name: 'Safari'
              };
              var version = getFirstMatch(commonVersionIdentifier, ua);

              if (version) {
                browser.version = parseFloat(version);
              }

              return browser;
            }
          },
          /* Something else */
          {
            test: [new RegExp('/.*/i')],
            describe: function describe(ua) {
              var regexpWithoutDeviceSpec = new RegExp('^(.*)/(.*) ');
              var regexpWithDeviceSpec = new RegExp('^(.*)/(.*)[ \t]((.*)');
              var hasDeviceSpec = ua.search('\\\\(') !== -1;
              var regexp = hasDeviceSpec ? regexpWithDeviceSpec : regexpWithoutDeviceSpec;
              return {
                name: getFirstMatch(regexp, ua),
                version: parseFloat(getSecondMatch(regexp, ua))
              };
            }
          }];

          var parseBrowser = function parseBrowser(ua) {
            var browserDescriptor = find(browsersList, function (_ref) {
              var test = _ref.test;
              return test.some(function (test) {
                return test.test(ua);
              });
            });
            return browserDescriptor === null || browserDescriptor === void 0 ? void 0 : browserDescriptor.describe(ua);
          };

          var isUnSupported = function isUnSupported(ua) {
            if (/MSIE|OPT|HeyTapBrowser|Trident/.test(ua)) {
              return true;
            }

            var browserInfo = parseBrowser(ua);
            if (!browserInfo) return false;
            if (!window.supportedBrowsersWithMinimumVersion) {
              console.error('No window.supportedBrowsersWithMinimumVersion defined');
              window.supportedBrowsersWithMinimumVersion = supportedBrowsersWithMinimumVersion;
            }
            return Boolean(find(window.supportedBrowsersWithMinimumVersion, function (_ref2) {
              var name = _ref2.name,
                  version = _ref2.version;

              if (!version || !browserInfo.version) return false;
              return Boolean(name === browserInfo.name && browserInfo.version < version);
            }));
          };

          var shouldRedirect = function shouldRedirect(ua) {
            var isSafePlace = document.location.pathname == '/' || new RegExp('/not-supported/?').test(document.location.pathname) || new RegExp('/(signup|company)/').test(document.location.pathname);
            return !isSafePlace && isUnSupported(ua);
          };

          if (shouldRedirect(navigator.userAgent)) {
            location.href = '/not-supported?url=' + encodeURIComponent(window.location.href);
          }
        })();
      `
      }</script>
    </Helmet>
  );
};

export const isInAppBrowser = (ua: string): boolean => {
  const rules = [
    // if it says it's a webview, let's go with that
    'WebView',
    // iOS webview will be the same as safari but missing "Safari"
    '(iPhone|iPod|iPad)(?!.*Safari)',
    // https://developer.chrome.com/docs/multidevice/user-agent/#webview_user_agent
    'Android.*Version/[0-9].[0-9]',
    // Also, we should save the wv detected for Lollipop
    // Android Lollipop and Above: webview will be the same as native but it will contain "wv"
    'Android.*wv',
    // old chrome android webview agent
    'Linux; U; Android',
  ];
  const regex = new RegExp(`(${rules.join('|')})`, 'ig');
  return Boolean(ua.match(regex));
};

export const useDetectNotSupportedBrowser = (noRedirect = false): boolean => {
  const [isUnSupported, setIsUnSupported] = React.useState(false);
  React.useEffect(() => {
    const ua = window.navigator.userAgent;
    const isInstagram = /Instagram/.test(ua);
    const isFB = /FB_IAB|FB4A|FBAV|FBAN/.test(ua);
    const isDuckDuckGo = /DuckDuckGo/.test(ua);

    // temporarily not support Edge on Android until we fix the issue
    // @see: https://blogs.windows.com/msedgedev/2017/10/05/microsoft-edge-ios-android-developer/
    const isEdgeOnAndroid = /EdgA/.test(ua);

    if (isFB || isInstagram || isDuckDuckGo || isInAppBrowser(ua) || isEdgeOnAndroid) {
      if (noRedirect) {
        setIsUnSupported(true);
        return;
      }
      location.href = '/not-supported?inapp=true&url=' + encodeURIComponent(window.location.href);
    }
  }, [noRedirect]);
  return isUnSupported;
};
